import { BrowserStorageCache } from "@aws-amplify/cache"

let tokenCache: typeof BrowserStorageCache | null = null

const getTokenCache = () => {
	tokenCache =
		tokenCache ??
		BrowserStorageCache.createInstance({
			storage: window.sessionStorage,
			keyPrefix: "tokenCache",
		})
	return tokenCache
}

export type JWT = `${string}.${string}.${string}`

export interface SignatureInfo {
	hasSignature: boolean
	signatureValid: boolean
	expired: boolean
}

const TOKEN_STORAGE_KEY = "signature"

export function storeSignature(sig: JWT) {
	const claims = parseJwt(sig)
	const expires = claims.exp * 1000
	getTokenCache().setItem(TOKEN_STORAGE_KEY, sig, { expires })
}

export function retrieveSignature(): JWT | null {
	return getTokenCache().getItem(TOKEN_STORAGE_KEY)
}

export function parseJwt(token: JWT) {
	var base64Url = token.split(".")[1]
	var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/")
	var jsonPayload = decodeURIComponent(
		window
			.atob(base64)
			.split("")
			.map(function (c) {
				return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
			})
			.join("")
	)

	return JSON.parse(jsonPayload)
}

export function getLinkId(sig) {
	const claims = parseJwt(sig)
	return claims.linkId ?? claims.uid
}

export function setUpSignatureLogin(sig: JWT) {
	const claims = parseJwt(sig)
	if (checkExpiry(claims)) {
		storeSignature(sig)
	} else {
		console.log("Signature has expired")
		return null
	}

	const response = {
		username: claims.sub,
		attributes: {
			...claims,
		},
	}
	return response
}

export function navigationAllowedOnSignature(sig: JWT) {
	const claims = parseJwt(sig)
	return claims.navigationAllowed
}

export function tearDownSignatureLogin() {
	console.log("Tearing down signature login")
	getTokenCache().removeItem("signature")
}

export function checkExpiry(claims: object) {
	const expiry = claims["exp"]
	const now = Date.now() / 1000
	if (expiry > now) {
		return true
	}
	return false
}

type TokenType = "signature" | "cognito"

export function setCurrentTokenType(location: TokenType) {
	getTokenCache().setItem("currentTokenType", location)
}

export function getCurrentTokenType(): TokenType | null {
	return getTokenCache().getItem("currentTokenType") || "cognito"
}
